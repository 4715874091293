














import { useGetters } from '@u3u/vue-hooks'
import { defineComponent } from '@vue/composition-api'

import HomeOrganizersCta from '@/components/home-organizers/Cta.vue'
import HomeOrganizersHero from '@/components/home-organizers/Hero.vue'
import HomeOrganizersEpisodes from '@/components/home-organizers/Episodes.vue'
import Personality360 from '@/components/personality/360.vue'
import PersonalityMoodboard from '@/components/personality/Moodboard.vue'

export default defineComponent({
  name: 'Personality',
  components: {
    HomeOrganizersCta,
    HomeOrganizersHero,
    HomeOrganizersEpisodes,
    Personality360,
    PersonalityMoodboard,
  },
  props: {},

  setup() {
    return {
      ...useGetters(['content']),
    }
  },
})
